import Decimal from 'decimal.js';
import { number } from 'zod';

export interface Amount {
  val: Decimal;
  maxValue?: number;
}

export const amountRefine = ({ val, maxValue }: Amount) => {
  try {
    if (val.isNaN() || val.isNegative()) {
      return false;
    }

    if (!maxValue) {
      return true;
    }

    return !val.gt(new Decimal(maxValue));
  } catch (err) {
    return false;
  }
};
