import { z } from 'zod';
import { Amount, amountRefine } from '../validations/amount';
import { requiredFieldMessage } from 'piramid-constants';
import Decimal from 'decimal.js';

export const amountSchema = (
  amount: Pick<Amount, 'maxValue'>,
  required: boolean = false,
) => {
  return z
    .string()
    .transform((val) => {
      if (val) return new Decimal(parseFloat(val.replace(/,/g, '')));
    })
    .refine(
      (val) => {
        if (val) return amountRefine({ val, maxValue: amount.maxValue });

        return true;
      },
      {
        message: `El monto máximo es de ${Number(
          amount.maxValue,
        ).toLocaleString()}`,
      },
    )
    .refine(
      (value) => {
        if (!value && required) return false;

        return true;
      },
      {
        message: requiredFieldMessage,
      },
    )
    .or(z.number());
};
