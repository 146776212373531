import { z } from 'zod';
import { sortTypes } from 'piramid-constants';

export const paginateRequestQuery = z.object({
  page: z.coerce.number().default(1),
  limit: z.coerce.number().min(1).max(20).default(10),
  createdAt: z.enum(sortTypes).optional(),
  search: z.string().optional(),
});

export type PaginateRequestQuery = z.infer<typeof paginateRequestQuery>;

export interface PaginationMeta {
  limit: number;
  is_first_page: boolean;
  is_last_page: boolean;
  current_page: number;
  previous_page: number | null;
  next_page: number | null;
  page_count: number;
  total_count: number;
}

export interface Pagination<T extends object> {
  meta: PaginationMeta;
  items: T[];
}
