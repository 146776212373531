import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  driverVehicleOwnership,
  requiredFieldMessage,
} from 'piramid-constants';
import { z } from 'zod';

export const driverSchema = z.object({
  email: z.string().email().optional(),
  ownership: z.enum(driverVehicleOwnership),
  first_name: z.string().min(1, requiredFieldMessage),
  cellphone: z
    .string()
    .optional()
    .refine((value) => !value || isValidPhoneNumber(value), {
      message: 'Celular inválido',
    }),
  last_name: z.string().min(1, requiredFieldMessage),
  address: z.string().optional(),

  country: z.string().min(1, requiredFieldMessage),
  state: z.string().min(1, requiredFieldMessage),

  city: z.string().optional(),
  birth_date: z.coerce.date().optional(),
  driver_license_number: z.string().optional(),
  driver_identification_number: z.string().optional(),
  driver_identification_expended_date: z.coerce.date().optional(),
});
