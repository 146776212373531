'use client';

import { checkInsurerAttribute } from '@/lib/checkInsurerAttribute';
import { getAgeByDateBirth } from '@/lib/get-age-by-date-birth';
import { TWENTY_FOUR_HOURS_REGEX } from '@/lib/parseTextTime';
import { amountSchema, cuitSchema, dni, occurrenceBaseSchema } from 'bff';
import { CountryCuit, MAX_CLAIMED_AMOUNT } from 'piramid-constants';
import {
  DamageTypes,
  ClaimantInstitutionRelationship,
  ClaimantTypes,
  CoverageTypes,
  EntrypointBackendTypes,
  IdentificationTypes,
  LegalPersonTypes,
  MaterialDamageTypes,
  PersonDamageDetailSeverityTypes,
  PersonDamageDetailTypes,
  PersonDamageTypes,
  PolicyHolderRelationshipTypes,
  SexTypes,
  TagAliases,
  VehicleCategoryTypes,
  VehicleUseTypes,
  VictimRelationshipTypes,
  VictimRoleTypes,
  VictimTypes,
  MaritalStatus,
} from 'database';
import Decimal from 'decimal.js';
import { LRUCache } from 'lru-cache';
import {
  DEFAULT_COUNTRY_CODE,
  LEGAL_PERSON_AGE_THRESHOLD,
  bodyParts,
  claimantVictimRelationships,
  companyServiceBackends,
  driverVehicleOwnership,
  realPropertyOwnershipTypes,
  realPropertyTypes,
  vehicleDocumentTypes,
  withLicensePlateVehicleTypes,
  withoutLicensePlateVehicleTypes,
} from 'piramid-constants';
import { z } from 'zod';
import { documentSchema } from './document';
import { licensePlateBaseSchema, vehicleSchema } from './vehicle';
import { occurrenceSchema } from 'bff';
import { damageSchema } from 'bff/contracts/damage.contract';
import { setHours, setMinutes } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const LOCAL_COUNTRY_CODE = 'ARG';

const formSteps = [
  'select-service',
  'claim-branch',
  'insured-info',
  'victim-type-choose',
  'victim-relationship',
  'own-claim-choose',
  'victim-natural-person-info',
  'victim-legal-person-info',
  'damage-declaration',
  'vehicle-driver-ownership',
  'vehicle-info',
  'vehicle-driver-info',
  'sinister-info',
  'claimed-amount',
  'location-info',
  'docs',
] as const;

export const STEP_DOCS_PREFIX = 'docs-' as const;

const victimRelationshipSchema = z
  .nativeEnum(VictimRelationshipTypes)
  .optional()
  .nullable()
  .nullish()
  .transform((val) => (!val ? undefined : val));

export type ClaimFormStep =
  | (typeof formSteps)[number]
  | `${typeof STEP_DOCS_PREFIX}${string}`;

export const damageTypesCombinations: Array<{
  type: DamageTypes;
  subtypes?: string[];
}>[] = [
  [
    {
      type: 'material',
    },
    {
      type: 'person',
      subtypes: ['injury'] satisfies PersonDamageTypes[],
    },
  ],
  [
    {
      type: 'material',
    },
    {
      type: 'person',
      subtypes: ['death'] satisfies PersonDamageTypes[],
    },
  ],
  [
    {
      type: 'person',
      subtypes: ['injury'] satisfies PersonDamageTypes[],
    },
  ],
  [
    {
      type: 'person',
      subtypes: ['death'] satisfies PersonDamageTypes[],
    },
  ],
  [
    {
      type: 'person',
      subtypes: ['death'] satisfies PersonDamageTypes[],
    },
    {
      type: 'material',
    },
  ],
  [
    {
      type: 'recovery',
    },
  ],
];

const claimBranches = ['automotive', 'other_risks'] as const;

export type ClaimBranch = (typeof claimBranches)[number];

const getEndpointFromCurrentLocation = () =>
  window.location.pathname.split('/').pop()!;

const occurrenceVehicleSchema = occurrenceBaseSchema
  .pick({
    date: true,
    time: true,
  })
  .extend({
    policyholder_license_plate: licensePlateBaseSchema,
    victim_identification_value: z.string().min(1),
    is_policyholder_vehicle_trailer: z.boolean().nullish().default(false),
  });

export const requiredFieldMessage = 'Campo requerido';

const policyNumberSchema = z.string().min(1, requiredFieldMessage);

const parentSchema = z.object({
  first_name: z.string().min(1, requiredFieldMessage),
  last_name: z.string().min(1, requiredFieldMessage),
  identification: dni,
  birth_date: z
    .date({
      invalid_type_error: requiredFieldMessage,
      required_error: requiredFieldMessage,
    })
    .refine((date) => !!date, {
      message: requiredFieldMessage,
    }),
});

export type ParentSchema = z.infer<typeof parentSchema>;

const generateNaturalPersonBaseSchema = (extendSchema: z.ZodRawShape) =>
  z.object({
    parents: z
      .object({
        father: parentSchema,
        mother: parentSchema,
      })
      .optional(),
    first_name: z.string().min(1, requiredFieldMessage),
    last_name: z.string().min(1, requiredFieldMessage),
    birth_date: z
      .date({
        invalid_type_error: requiredFieldMessage,
        required_error: requiredFieldMessage,
      })
      .refine((date) => !!date, {
        message: requiredFieldMessage,
      }),
    nationality: z.string().min(1, requiredFieldMessage),

    address_street: z.string().min(1, requiredFieldMessage),
    address_number: z.string().min(1, requiredFieldMessage),
    address_apartment: z.string().nullish(),

    state: z.string().min(1, requiredFieldMessage),
    city: z.string().min(1, requiredFieldMessage),
    zip_code: z
      .string()
      .min(1, requiredFieldMessage)
      .regex(/^\d{4}$/, {
        message: 'Debe tener 4 dígitos',
      }),
    sex: z.nativeEnum(SexTypes, {
      invalid_type_error: requiredFieldMessage,
      required_error: requiredFieldMessage,
    }),
    identification_type: z.nativeEnum(IdentificationTypes, {
      invalid_type_error: requiredFieldMessage,
      required_error: requiredFieldMessage,
    }),
    identification_value: z.string().min(1, requiredFieldMessage),
    cellphone: z.coerce
      .string()
      .min(1, requiredFieldMessage)
      .refine(isValidPhoneNumber, {
        message: 'Celular inválido',
      }),
    ...extendSchema,
  });

const naturalPersonSchema = generateNaturalPersonBaseSchema({})
  .extend({})
  .refine(
    (val) => {
      if (val.identification_type === 'national_id_document')
        return dni.safeParse(val.identification_value).success;

      return true;
    },
    {
      message: 'Documento inválido',
      path: ['identification_value'],
    },
  );

export type NaturalPersonSchema = z.infer<typeof naturalPersonSchema>;

export const baseLegalPersonSchema = z.object({
  type: z.nativeEnum(LegalPersonTypes),
  social_reason: z.string().min(1, requiredFieldMessage),
  address: z.string().min(1, requiredFieldMessage),
  state: z.string().min(1, requiredFieldMessage),
  city: z.string().min(1, requiredFieldMessage),
  zip_code: z
    .string()
    .min(1, requiredFieldMessage)
    .regex(/^\d{4}$/, {
      message: 'Debe tener 4 dígitos',
    }),
});

const legalPersonSchema = z.discriminatedUnion('country', [
  baseLegalPersonSchema.extend({
    country: z.literal('ARG' satisfies CountryCuit),
    cuit: cuitSchema('ARG'),
  }),
  baseLegalPersonSchema.extend({
    country: z.literal('PRY' satisfies CountryCuit),
    cuit: cuitSchema('PRY'),
  }),
]);

const victimUnion = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('natural_person' satisfies VictimTypes),
    natural_person: generateNaturalPersonBaseSchema({
      marital_status: z.nativeEnum(MaritalStatus, {
        invalid_type_error: requiredFieldMessage,
        required_error: requiredFieldMessage,
      }),
    }),
  }),
  z.object({
    type: z.literal('legal_person' satisfies VictimTypes),
    legal_person: legalPersonSchema,
  }),
]);

export type VictimSchema = z.infer<typeof victimUnion>;

const claimantNaturalPersonSchema = z.object({
  victim_relationship_other: z.string().nullish(),
  victim_relationship: victimRelationshipSchema,
  type: z.literal('natural_person' satisfies ClaimantTypes),
  natural_person: generateNaturalPersonBaseSchema({
    email: z
      .string({
        invalid_type_error: requiredFieldMessage,
      })
      .min(1, requiredFieldMessage)
      .email(),
  })
    .refine(
      ({ birth_date, parents }) => {
        if (!birth_date) return false;

        if (
          getAgeByDateBirth(birth_date) < LEGAL_PERSON_AGE_THRESHOLD &&
          !parents
        ) {
          return false;
        }

        return true;
      },
      {
        message: 'Debe ingresar los datos de los padres',
        path: ['parents'],
      },
    )
    .refine(
      (val) => {
        if (val.identification_type === 'national_id_document')
          return dni.safeParse(val.identification_value).success;

        return true;
      },
      {
        message: 'Documento inválido',
        path: ['identification_value'],
      },
    ),
});

const claimantLegalPersonSchema = z.object({
  victim_relationship_other: z.string().nullish(),
  victim_relationship: victimRelationshipSchema,
  type: z.literal('legal_person' satisfies ClaimantTypes),
  legal_person: legalPersonSchema,
});

export const claimantSchema = z.discriminatedUnion('type', [
  claimantNaturalPersonSchema,
  claimantLegalPersonSchema,
]);
export type ClaimantSchema = z.infer<typeof claimantSchema>;

export const sinisterTypeOptions = [
  'policyholder_vehicle',
  'policyholder_vehicle_foreign',
  'policyholder',
] as const;

export type SinisterType = (typeof sinisterTypeOptions)[number];

export const claimBaseSchema = z.object({
  savedAt: z.coerce.date(),
  serviceId: z.number(),
  history: z
    .array(
      z.object({
        step: z.enum(formSteps),
      }),
    )
    .default([]),
  documents: z.array(documentSchema).default([]),
  claimed_amount: z.coerce.number().min(1).max(MAX_CLAIMED_AMOUNT),
  policyholder_policy_number: policyNumberSchema,
  claimId: z.number().optional().nullable().nullish(),
  isLocalInsuredInvolved: z.boolean(),
  damages: z.array(damageSchema).default([]),
});

export const rivadaviaClaimSchema = claimBaseSchema.extend({
  location: z
    .object({
      desired_support_location_state_municipality: z.string().optional(),
      desired_support_location_state: z.string().min(1, requiredFieldMessage),
    })
    .refine(
      ({
        desired_support_location_state,
        desired_support_location_state_municipality,
      }) => {
        if (
          ['B', 'S'].includes(desired_support_location_state) &&
          !desired_support_location_state_municipality
        ) {
          return false;
        }

        return true;
      },
      {
        path: ['desired_support_location_state_municipality'],
        message: requiredFieldMessage,
      },
    ),
  occurrenceVehicle: occurrenceVehicleSchema,
  occurrence: occurrenceSchema.refine(
    (arg) => {
      if (!arg.criminal_case_number) return true;

      return !!arg.court;
    },
    {
      path: ['court'],
      message: 'Debe especificar número de juzgado',
    },
  ),
  claimants: z
    .array(
      claimantSchema.refine(
        (claimant) => {
          if (
            claimant.victim_relationship === 'other' &&
            !claimant.victim_relationship_other
          )
            return false;

          return true;
        },
        {
          message: 'Debe indicar el vinculo',
          path: ['victim_relationship_other'],
        },
      ),
    )
    .min(1, 'Debe haber al menos un reclamante'),
  isOwnClaim: z.boolean(),
  sinisterType: z.enum(sinisterTypeOptions),
  victim: victimUnion,
});

export type RivadaviaClaimSchema = z.infer<typeof rivadaviaClaimSchema>;

export const tajyClaimSchema = claimBaseSchema.extend({});

export const unionClaimSchema = z.discriminatedUnion('backend', [
  rivadaviaClaimSchema.merge(
    z.object({
      backend: z.literal('rivadavia' satisfies EntrypointBackendTypes),
    }),
  ),
  tajyClaimSchema.merge(
    z.object({
      backend: z.literal('tajy' satisfies EntrypointBackendTypes),
    }),
  ),
]);

export type UnionClaimSchema = z.infer<typeof unionClaimSchema>;

export type ClaimFormData = Extract<UnionClaimSchema, { backend: 'rivadavia' }>;

export type DeepPartialClaimFormData = z.infer<typeof rivadaviaClaimSchema>;
