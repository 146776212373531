import { TagAliases } from 'database';
import { z } from 'zod';

export const documentSchema = z.object({
  name: z.string(),
  size: z.number(),
  token: z.string(),
  alias: z.nativeEnum(TagAliases),
});

export type DocumentSchema = z.infer<typeof documentSchema>;
