import {
  Claim,
  DamageTypes,
  ClaimHandlerDelegated,
  ClaimantInstitutionRelationship,
  ClaimantTypes,
  CoverageTypes,
  DriverOwnershipTypes,
  EntrypointBackendTypes,
  File,
  IdentificationTypes,
  LegalPersonTypes,
  MaterialDamageTypes,
  PersonDamageDetailSeverityTypes,
  PersonDamageDetailTypes,
  PersonDamageTypes,
  PersonParentTypes,
  PolicyHolderRelationshipTypes,
  Prisma,
  RealPropertyOwnershipTypes,
  RealPropertyTypes,
  SexTypes,
  Tag,
  TagAliases,
  VehicleCategoryTypes,
  VehicleDocumentTypes,
  VehicleTypes,
  VehicleUseTypes,
  VictimRelationshipTypes,
  VictimRoleTypes,
  VictimTypes,
  CompanyClaimStatusOrderRule,
  ExternalComplaint,
  MaritalStatus,
  ClaimSystemStatus,
  CompanyClaimReference,
  CompanyClaimStage,
  ClaimStatusTransition,
  CompanyComplaintStage,
} from 'database';
import { Decimal } from 'decimal.js';
import {
  ClaimCheckAttribute,
  CountryCuit,
  DEFAULT_COUNTRY_CODE,
  DelegateClaimStrategy,
  DeriveClaimStrategy,
  MAX_CLAIMED_AMOUNT,
  bodyParts,
  claimCheckAttributes,
  claimCombinations,
  claimReportColumns,
  claimTypes,
  countryAndStates,
  fieldAdjusterClaimStatus,
  requiredFieldMessage,
} from 'piramid-constants';
import { z } from 'zod';
import { Pagination, paginateRequestQuery } from './pagination.contract';
import { InferredEntityUnion } from './sinister.contract';
import { countryRefine, stateRefine } from '../validations/country';
import { FileWithURL, listFilesRequestQuery } from './file.contracts';
import { FetchResourceDetailRequestParams } from './detail.contract';
import { cuitSchema, dni } from './types.contract';
import { amountSchema } from './amount.contract';
import { RetrieveRelationTypeTimelineSLAResponseBody } from './sla.contract';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const generateNaturalPersonSchema = <T extends z.ZodRawShape>(
  extendSchema: T,
) => {
  return z
    .object({
      address_street: z.string().min(1),
      address_number: z.string().min(1),
      address_apartment: z.string().nullish(),

      parents: z
        .array(
          z.object({
            first_name: z.string().min(1),
            last_name: z.string().min(1),
            birth_date: z.coerce.date(),

            identification: dni,
            type: z.nativeEnum(PersonParentTypes),
          }),
        )
        .default([]),
      identification_value: z.string().min(1),
      identification_type: z.nativeEnum(IdentificationTypes),
      sex: z.nativeEnum(SexTypes),
      //TODO: in nodejs context we need to validate it querying db
      zip_code: z
        .string()
        .min(1)
        .regex(/^\d{4}$/, {
          message: 'Debe tener 4 dígitos',
        }),
      cellphone: z.coerce
        .string()
        .min(1, requiredFieldMessage)
        .refine(isValidPhoneNumber, {
          message: 'Número de celular inválido',
        }),
      state: z.string().nullish(),
      city: z.string(),
      first_name: z.string().min(1),
      last_name: z.string().min(1),
      birth_date: z.coerce.date(),
      nationality: z.string().min(1),
      ...extendSchema,
    })
    .refine(
      (arg) => {
        const countryStates =
          countryAndStates.find((country) => country.code === arg.nationality)
            ?.states || [];

        if (!countryStates.length) return true;

        //@ts-ignore
        if (arg.state) return stateRefine(arg, (data) => data.nationality);

        return false;
      },
      {
        path: ['state'],
        message: 'Invalid state for country',
      },
    );
};

export const legalPersonSchema = z.object({
  type: z.nativeEnum(LegalPersonTypes).optional(),
  social_reason: z.string().min(1),
  address: z.string().min(1),
  state: z.string().min(1),
  city: z.string().min(1),
  zip_code: z
    .string()
    .min(1)
    .regex(/^\d{4}$/, {
      message: 'Debe tener 4 dígitos',
    }),
  cuit: cuitSchema(),
});

export const materialDamageBaseSchema = z.object({
  insurance: z
    .object({
      coverage: z.nativeEnum(CoverageTypes),
      insurer: z.string().min(1),
      policy_number: z.string().min(1),
      franchise_amount: z.coerce.number().optional().nullish().nullable(),
    })
    .refine(
      ({ coverage, franchise_amount }) => {
        if (coverage === 'all_risks_with_franchise' && !franchise_amount) {
          return false;
        }

        if (franchise_amount) {
          try {
            return new Decimal(franchise_amount);
          } catch (err) {
            console.log(err);
            return false;
          }
        }

        return true;
      },
      {
        message: requiredFieldMessage,
        path: ['franchise_amount'],
      },
    )
    .optional()
    .nullish(),
});

const damage = z.discriminatedUnion('type', [
  z.object({
    description: z.string(),
    fixed: z.boolean(),
    type: z.literal('material' satisfies DamageTypes),
    person: z.any().default(null),
    material: z.discriminatedUnion('type', [
      materialDamageBaseSchema.extend({
        type: z.literal('vehicle' satisfies MaterialDamageTypes),
        vehicle: z.object({
          document_type: z
            .nativeEnum(VehicleDocumentTypes)
            .default('other' satisfies VehicleDocumentTypes),
          type: z.nativeEnum(VehicleTypes),
          driver: z
            .union([
              z.object({
                ownership: z.literal('owner' satisfies DriverOwnershipTypes),
              }),
              z
                .object({
                  ownership: z.literal('third' satisfies DriverOwnershipTypes),
                  first_name: z.string().min(1),
                  last_name: z.string().min(1),
                  cellphone: z.string().min(1).refine(isValidPhoneNumber, {
                    message: 'Número de celular inválido',
                  }),
                  country: z.string().min(1).refine(countryRefine),
                  state: z.string().min(1),
                })
                .refine((data) => stateRefine(data, (data) => data.country)),
              z.object({
                ownership: z.literal(undefined).or(z.literal(null)),
              }),
            ])
            .optional()
            .transform((driver) => {
              if (!driver?.ownership) return null;

              return driver;
            }),
          make: z.string().min(1),
          year: z.coerce
            .number()
            .refine((val) => new Date().getFullYear() >= val, {
              message: `No debe ser mayor a ${new Date().getFullYear()}`,
            }),
          use: z.nativeEnum(VehicleUseTypes),
          model: z.string().min(1),
          version: z.string().min(1),
          category: z.nativeEnum(VehicleCategoryTypes),
          market_value: amountSchema({}).nullish(),
          license_plate: z.string().optional().nullable().nullish(),
          chassis: z.string().optional().nullable().nullish(),
          engine: z.string().optional().nullable().nullish(),
        }),
      }),
      materialDamageBaseSchema.extend({
        type: z.literal('real_property' satisfies MaterialDamageTypes),
        real_property: z
          .object({
            country: z.string().min(1).refine(countryRefine),

            address_street: z.string().min(1),
            address_number: z.string().min(1),
            address_apartment: z.string().nullish(),

            state: z.string().min(1),
            city: z.string().min(1),
            zip_code: z
              .string()
              .min(1)
              .regex(/^\d{4}$/, {
                message: 'Debe tener 4 dígitos',
              }),
            type: z.nativeEnum(RealPropertyTypes),
            ownership: z.nativeEnum(RealPropertyOwnershipTypes),
            other_ownership: z.string().nullish(),
            other_type: z.string().nullish(),
          })
          .refine((arg) => stateRefine(arg, (data) => data.country))
          .refine(
            (values) => {
              if (values.type === 'other' && !values.other_type) return false;

              return true;
            },
            {
              path: ['other_type'],
              message: 'other_type is required when type is other',
            },
          )
          .refine(
            (values) => {
              if (values.ownership === 'other' && !values.other_ownership)
                return false;

              return true;
            },
            {
              path: ['other_ownership'],
              message: 'other_ownership is required when ownership is other',
            },
          ),
      }),
    ]),
  }),
  z.object({
    material: z.any().default(null),
    description: z.string(),
    fixed: z.boolean(),
    type: z.literal('person' satisfies DamageTypes),
    person: z
      .object({
        driver: z
          .object({
            first_name: z.string().min(1),
            last_name: z.string().min(1),
            cellphone: z.string().min(1).refine(isValidPhoneNumber, {
              message: 'Número de celular inválido',
            }),
            country: z.string().min(1).refine(countryRefine),
            state: z.string().min(1),
          })
          .optional()
          .nullish()
          .default(null)
          .refine((data) =>
            data?.country ? stateRefine(data, (data) => data.country) : true,
          ),
        hadSurgery: z.boolean().nullish(),
        wasTreatedByWorkerCompensationInsurer: z.boolean().nullish(),
        wasTreatedByMedicalInstitution: z.boolean().nullish(),
        details: z
          .array(
            z.object({
              body_parts: z.array(z.enum(bodyParts)).default([]).optional(),
              severity: z.nativeEnum(PersonDamageDetailSeverityTypes),
              damage: z.nativeEnum(PersonDamageDetailTypes),
            }),
          )
          .default([]),
        surgery_description: z.string().optional().nullable().nullish(),
        type: z.nativeEnum(PersonDamageTypes),
        health_insurance_fund: z.string().nullish(),
        treatment_worker_compensation_insurer: z
          .string()
          .optional()
          .nullable()
          .nullish(),
        treatment_medical_institution: z
          .string()
          .optional()
          .nullable()
          .nullish(),
        victim_role: z.nativeEnum(VictimRoleTypes).nullish(),
        policyholder_relationship: z
          .nativeEnum(PolicyHolderRelationshipTypes)
          .nullish(),
        policyholder_relationship_other: z.string().nullish(),
      })
      .refine(
        ({ victim_role, policyholder_relationship }) => {
          if (!victim_role) return true;

          if (
            victim_role === 'policyholder_rider' &&
            !policyholder_relationship
          )
            return false;

          return true;
        },
        {
          message: `When victim_role is policyholder_rider, policyholder_relationship is required`,
          path: ['policyholder_relationship'],
        },
      )
      .transform((personDamage) => {
        //we just make sure when person damage is death, details about injuries are not present
        if (personDamage.type === 'death')
          return {
            ...personDamage,
            details: [],
          } satisfies typeof personDamage;

        return personDamage;
      })
      .transform((personDamage) => {
        if (
          personDamage.victim_role !== 'policyholder_rider' &&
          personDamage.policyholder_relationship
        )
          return {
            ...personDamage,
            policyholder_relationship: undefined,
          } satisfies typeof personDamage;

        return personDamage;
      })
      .refine(
        (personDamage) => {
          if (
            personDamage.policyholder_relationship === 'other' &&
            !personDamage.policyholder_relationship_other
          )
            return false;

          return true;
        },
        {
          message: 'Debe indicar el vinculo',
          path: ['policyholder_relationship_other'],
        },
      ),
  }),
  z.object({
    description: z.string(),
    fixed: z.boolean(),
    type: z.literal('recovery' satisfies DamageTypes),
    recovery: z.object({
      claimant_institution_relationship: z.nativeEnum(
        ClaimantInstitutionRelationship,
      ),
      institution: legalPersonSchema,
    }),
  }),
]);

export type ClaimDamageSchema = z.infer<typeof damage>;

export const documentSchema = z.object({
  alias: z.nativeEnum(TagAliases),
  token: z.string().min(1, requiredFieldMessage),
});

export type DocumentSchema = z.infer<typeof documentSchema>;

export const createClaimSchema = z.object({
  endpoint: z.string().min(1),
  policyholder_policy_number: z.string().nullable().nullish(),
  policyholder_license_plate: z
    .string()
    .nullable()
    .nullish()
    .transform((v) => v?.toUpperCase()),
  is_policyholder_vehicle_trailer: z.boolean().nullish(),
  damages: z
    .array(damage)
    .min(1)
    .refine(
      (damages) =>
        claimCombinations.some(
          (combination) =>
            combination.length === damages.length &&
            combination.every((combinationDamage) =>
              damages.some((d) => d.type === combinationDamage),
            ),
        ),
      {
        message: 'Invalid combination of damages',
      },
    )
    .refine(
      (damages) => {
        const personDamage = damages.find(
          (damage): damage is Extract<typeof damage, { type: 'person' }> =>
            damage.type === 'person' && damage.person.victim_role === 'rider',
        );

        const mustRequireDriverInfo =
          damages.some(
            (damage) =>
              damage.type === 'material' && damage.material.type === 'vehicle',
          ) && !!personDamage;

        if (mustRequireDriverInfo) return !!personDamage.person.driver;

        return true;
      },
      {
        message:
          'Driver info is required when there is a person damage and a vehicle damage',
        path: ['damages'],
      },
    ),
  victim: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('natural_person' satisfies VictimTypes),
      natural_person: generateNaturalPersonSchema({
        marital_status: z.nativeEnum(MaritalStatus),
      }),
      legal_person: z.any().nullish().default(null),
    }),
    z.object({
      type: z.literal('legal_person' satisfies VictimTypes),
      legal_person: legalPersonSchema,

      natural_person: z.any().nullish().default(null),
    }),
  ]),
  claimants: z.array(
    z
      .discriminatedUnion('type', [
        z.object({
          victim_relationship_other: z.string().nullish(),
          victim_relationship: z
            .nativeEnum(VictimRelationshipTypes)
            .optional()
            .nullable()
            .nullish(),
          type: z.literal('natural_person' satisfies ClaimantTypes),
          natural_person: generateNaturalPersonSchema({
            email: z.string().min(1, requiredFieldMessage).email(),
          }),
          //This is just a way to avoid diff when use compact-diff
          legal_person: z.any().nullish().default(null),
        }),
        z.object({
          victim_relationship_other: z.string().nullish(),
          victim_relationship: z
            .nativeEnum(VictimRelationshipTypes)
            .optional()
            .nullable()
            .nullish(),
          type: z.literal('legal_person' satisfies ClaimantTypes),
          legal_person: legalPersonSchema,
          //This is just a way to avoid diff when use compact-diff
          natural_person: z.any().nullish().default(null),
        }),
      ])
      .refine(
        (claimant) => {
          if (
            claimant.victim_relationship === 'other' &&
            !claimant.victim_relationship_other
          )
            return false;

          return true;
        },
        {
          message: 'Debe indicar el vinculo',
          path: ['victim_relationship_other'],
        },
      ),
  ),
  desired_support_location_state: z
    .string()
    .min(1)
    .refine((val) => {
      if (!val) return false;

      const country = countryAndStates.find(
        (c) => c.code === DEFAULT_COUNTRY_CODE,
      );

      return country!.states.some((s) => s.code === val);
    }),
  desired_support_location_state_municipality: z
    .string()
    .optional()
    .nullable()
    .nullish(),
  occurrence: z
    .object({
      police_station: z.string().optional().nullable().nullish(),
      criminal_case_number: z.coerce
        .string()
        .max(6, 'Máximo 6 dígitos')
        .regex(/^\d+$/, 'Unicamente dígitos')
        .nullable()
        .nullish()
        .or(z.literal(''))
        .transform((value) => (!value ? undefined : value)),
      court: z.coerce
        .string()
        .regex(/^\d+$/, 'Unicamente dígitos')
        .max(3, 'Máximo 3 caracteres')
        .optional()
        .nullable()
        .nullish()
        .or(z.literal(''))
        .transform((value) => (!value ? undefined : value)),
      clerks_office: z.coerce
        .string()
        .regex(/^\d+$/, 'Unicamente dígitos')
        .max(3, 'Máximo 3 caracteres')
        .optional()
        .nullable()
        .nullish()
        .or(z.literal(''))
        .transform((value) => (!value ? undefined : value)),
      judicial_department: z.string().optional().nullable().nullish(),
      address: z.string().min(1),
      sinister_date: z.coerce
        .date()
        .refine((date) => new Date(date).getTime() < new Date().getTime(), {
          message: 'Sinister date must be in the past',
        }),
      state: z.string().min(1),
      country: z
        .string()
        .min(1)
        .refine(countryRefine)
        .default(DEFAULT_COUNTRY_CODE),
      city: z.string().min(1),
      zip_code: z
        .string()
        .min(1)
        .regex(/^\d{4}$/, {
          message: 'Debe tener 4 dígitos',
        }),
      description: z.string().min(1),
    })
    .refine((arg) => stateRefine(arg, (data) => data.country), {
      path: ['state'],
      message: 'Invalid state for country',
    })
    .refine(
      (arg) => {
        if (!arg.criminal_case_number) return true;

        return !!arg.court;
      },
      {
        path: ['court'],
        message: 'Debe especificar número de juzgado',
      },
    ),
  documents: z.array(documentSchema).default([]),
  claimed_amount: z.coerce.number().min(1).max(MAX_CLAIMED_AMOUNT),
});

export const createClaimRequestBody = createClaimSchema
  .refine(
    (arg) => {
      if (
        !arg.occurrence.sinister_date &&
        arg.damages.some(
          (d) => d.type === 'material' && d.material.type === 'vehicle',
        )
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'sinister_date is required when there is a vehicle damage',
      path: ['occurrence', 'sinister_date'],
    },
  )
  .refine(
    (arg) => {
      if (!arg.policyholder_license_plate && !arg.policyholder_policy_number) {
        return false;
      }

      return true;
    },
    {
      message:
        'policyholder_license_plate or policy_number must be define in order to identify sinister',
    },
  )
  .refine(
    (arg) => {
      if (arg.policyholder_license_plate && !arg.occurrence.sinister_date) {
        return false;
      }

      return true;
    },
    {
      message:
        'sinister_date is required when policyholder_license_plate is defined',
      path: ['occurrence', 'sinister_date'],
    },
  );

export type CreateClaimRequestBody = z.infer<typeof createClaimRequestBody>;

export interface CreateClaimResponseBody {
  id: number;
  sinister_id: number;
  effective_date: Date;
}

export const checkClaimAttributeRequestQuery = z.discriminatedUnion(
  'attribute',
  [
    z.object({
      attribute: z.literal('policy_number' satisfies ClaimCheckAttribute),
      value: z.string().min(1),
      endpoint: z.string().min(1),
    }),
    z.object({
      attribute: z.literal('license_plate' satisfies ClaimCheckAttribute),
      value: z.string().min(1),
      endpoint: z.string().min(1),
      sinister_date: z.coerce.date(),
      victim_identification_value: z.string().min(1),
      victim_identification_type: z.nativeEnum(IdentificationTypes),
      is_policyholder_vehicle_trailer: z
        .string()
        .transform((value) => value === 'true'),
    }),
    z.object({
      attribute: z.literal('zip_code' satisfies ClaimCheckAttribute),
      endpoint: z.string().min(1),
      value: z
        .string()
        .min(1)
        .transform((v) => v.trim()),
      country: z.string().min(1),
      state: z.string().min(1),
    }),
  ],
);

export type CheckClaimAttributeRequestQuery = z.infer<
  typeof checkClaimAttributeRequestQuery
>;

export interface CheckClaimAttributeResponseBody {
  valid: boolean;
}

export const paginateClaimsRequestQuery = paginateRequestQuery.extend({
  damages: z.array(z.nativeEnum(DamageTypes)).default([]).optional(),
});

export type PaginateClaimsRequestQuery = z.infer<
  typeof paginateClaimsRequestQuery
>;

export const listInsurerClaimsRequestQuery = paginateRequestQuery.extend({
  user_id: z.coerce.number().nullable().nullish(),
  states: z.array(z.string()).default([]),
  municipalities: z.array(z.string()).default([]),
  claim_damages: z.array(z.nativeEnum(DamageTypes)).default([]),
  date_from: z.coerce.date().optional(),
  date_to: z.coerce.date().optional(),

  claimed_amount_from: amountSchema({}).optional(),
  claimed_amount_to: amountSchema({}).optional(),

  coordinator: z.array(z.coerce.number().min(1)).default([]),
  victim_relationship: z
    .array(z.nativeEnum(VictimRelationshipTypes))
    .default([]),
  target_group: z.array(z.coerce.number().min(1)).default([]),
  responsible: z.array(z.coerce.number().min(1)).default([]),
  system_status: z.array(z.nativeEnum(ClaimSystemStatus)).default([]),

  status: z.array(z.string().min(1)).default([]),
  references: z.array(z.string().min(1)).default([]),
  stages: z.array(z.string().min(1)).default([]),

  type: z.array(z.enum(claimTypes)).default([]),
  ramo: z.array(z.string().min(1)).default([]),
});

export type ListInsurerClaimsRequestQuery = z.infer<
  typeof listInsurerClaimsRequestQuery
>;

export interface ListInsurerClaimsResponseBody
  extends Pagination<
    Prisma.ClaimGetPayload<{
      include: {
        stage: true;
        reference: true;
        company: {
          select: {
            id: true;
            country: true;
          };
        };
        claimants: {
          select: {
            victim_relationship: true;
          };
        };
        ramo: true;
        claim_status: true;
        occurrence: true;
        victim: {
          include: {
            legal_person: true;
            natural_person: true;
          };
        };
        handlers: {
          include: {
            user: {
              select: {
                id: true;
                first_name: true;
                last_name: true;
                email: true;
                profile_photo: true;
              };
            };
          };
        };
        damages: true;
      };
    }> & {
      sinister: {
        id: number;
        external_complaint?: ExternalComplaint;
      };
      objectives?:
        | RetrieveRelationTypeTimelineSLAResponseBody['objectives']
        | null;
    }
  > {}

export type ClaimWithPartialRelations = Prisma.ClaimGetPayload<{
  include: {
    damages: {
      include: {
        material: true;
        person: true;
        recovery: true;
      };
    };
    entrypoint: true;
    victim: {
      include: {
        natural_person: true;
        legal_person: true;
      };
    };
    occurrence: true;
  };
}>;

export type ClaimWithRelations = Prisma.ClaimGetPayload<{
  include: {
    documents: true;
    claimants: {
      include: {
        natural_person: true;
      };
    };
    reference: true;
    damages: true;
    entrypoint: true;
    victim: {
      include: {
        natural_person: true;
        legal_person: true;
      };
    };
    occurrence: true;
  };
}>;

export type ClaimWithDeepRelations = Prisma.ClaimGetPayload<{
  include: {
    documents: true;
    claimants: {
      include: {
        natural_person: true;
      };
    };
    damages: {
      include: {
        claim: true;
        material: {
          include: {
            real_property: true;
            vehicle: true;
          };
        };
        person: true;
        recovery: true;
      };
    };
    entrypoint: true;
    victim: {
      include: {
        natural_person: true;
        legal_person: true;
      };
    };
    occurrence: true;
  };
}>;

export const fetchClaimDetailRequestParams = z.object({
  id: z.coerce.number().min(1),
});

export type FetchClaimDetailRequestParams = z.infer<
  typeof fetchClaimDetailRequestParams
>;

export type FetchInsurerClaimDetailResponseBody = Omit<
  Prisma.ClaimGetPayload<{
    include: {
      handlers: {
        select: {
          id: true;
          type: true;
          user: {
            select: {
              id: true;
              first_name: true;
              last_name: true;
              profile_photo: true;
              email: true;
            };
          };
        };
      };
      created_by: {
        select: {
          profession: true;
          first_name: true;
          last_name: true;
          email: true;
          profile_photo: true;
        };
      };
      entrypoint: true;
      documents: true;
      claimants: {
        include: {
          natural_person: {
            include: {
              parents: true;
            };
          };
          legal_person: true;
        };
      };
      damages: {
        include: {
          material: {
            include: {
              insurance: true;
              vehicle: true;
              real_property: true;
            };
          };
          claim: true;
          person: true;
          recovery: {
            include: {
              institution: true;
            };
          };
        };
      };
      victim: {
        include: {
          natural_person: {
            include: {
              parents: true;
            };
          };
          legal_person: true;
        };
      };
      occurrence: true;
      claim_status: true;
      reference: true;
    };
  }>,
  'documents'
> & {
  documents: Array<File & { url: string }>;
};

export interface FetchFieldAdjusterClaimDetailRequestParams {
  claimId: number;
}

export interface FetchFieldAdjusterClaimDetailResponseBody
  extends FetchInsurerClaimDetailResponseBody {}

export type FetchClaimDetailResponseBody = Omit<
  Prisma.ClaimGetPayload<{
    include: {
      company: {
        select: {
          id: true;
          country: true;
        };
      };
      created_by: {
        select: {
          first_name: true;
          last_name: true;
          email: true;
          profile_photo: true;
        };
      };
      entrypoint: true;
      documents: true;
      claimants: {
        include: {
          natural_person: true;
          legal_person: true;
        };
      };
      damages: {
        include: {
          material: {
            include: {
              insurance: true;
              vehicle: true;
              real_property: true;
            };
          };
          claim: true;
          person: true;
          recovery: {
            include: {
              institution: true;
            };
          };
        };
      };
      victim: {
        include: {
          natural_person: true;
          legal_person: true;
        };
      };
      reference: true;
      occurrence: true;
      stage: true;
      claim_type: true;
    };
  }>,
  'documents'
> & {
  documents: Array<File & { url: string }>;
};

export interface PaginateClaimsResponseBody
  extends Pagination<ClaimWithPartialRelations> {}

export const addMessageToClaimRequestParams = fetchClaimDetailRequestParams;

export type AddMessageToClaimRequestParams = z.infer<
  typeof addMessageToClaimRequestParams
>;

export const addMessageRequestBody = z.object({
  message: z.string().min(1, requiredFieldMessage),
});

export type AddMessageRequestBody = z.infer<typeof addMessageRequestBody>;

export interface AddMessageResponseBody {
  id: number;
}

export type MessageWithRelations = Prisma.MessageGetPayload<{
  include: {
    file_request: {
      include: {
        placeholders: {
          include: {
            files: {
              include: {
                uploaded_by: true;
              };
            };
            types: true;
            tag: true;
          };
        };
      };
    };
    sender: {
      select: {
        id: true;
        first_name: true;
        last_name: true;
      };
    };
  };
}>;

export interface ListMessagesResponseBody {
  messages: MessageWithRelations[];
}

export type MaterialDamageWithRelations = Prisma.MaterialDamageGetPayload<{
  include: {
    real_property: true;
    vehicle: true;
  };
}>;

export type ClaimDamageWithRelations = NonNullable<
  Prisma.DamageGetPayload<{
    include: {
      recovery: {
        include: {
          institution: true;
        };
      };
      material: {
        include: {
          insurance: true;
          vehicle: true;
        };
      };
      claim: true;
      person: {
        include: {
          driver: true;
          details: true;
        };
      };
    };
  }>
>;

export type VehicleWithRelations = Prisma.VehicleGetPayload<{
  include: {
    driver: true;
  };
}>;

export const addClaimCollaboratorRequestBody = z.object({
  userId: z.coerce.number().min(1, requiredFieldMessage),
});

export type AddClaimCollaboratorRequestBody = z.infer<
  typeof addClaimCollaboratorRequestBody
>;

export const assignClaimResponsibleRequestBody = z.object({
  userId: z.coerce.number().min(1, requiredFieldMessage),
});

export type AssignClaimResponsibleRequestBody = z.infer<
  typeof assignClaimResponsibleRequestBody
>;

export interface AssignClaimResponsibleResponseBody {
  assigned: boolean;
}

export interface ListClaimFilesResponseBody {
  files: Array<
    Prisma.FileGetPayload<{
      include: {
        validations: {
          select: {
            valid: true;
            validation: true;
            id: true;
            created_at: true;
          };
        };
        _count: {
          select: {
            validations: true;
            entities: true;
          };
        };
        uploaded_by: {
          select: {
            id: true;
            first_name: true;
            last_name: true;
            email: true;
          };
        };
        tags: true;
      };
    }> & { url: string; previewURL: string; entities: InferredEntityUnion[] }
  >;
}

export const updateClaimStatusRequestParams = z.object({
  statusId: z.coerce.number().min(1, requiredFieldMessage),
  claimId: z.coerce.number().min(1, requiredFieldMessage),
});

export type UpdateClaimStatusRequestParams = z.infer<
  typeof updateClaimStatusRequestParams
>;

export const updateRelationTypeStatusRequestBody = z.object({
  action_output_id: z.coerce.number().nullish(),
});

export type UpdateRelationTypeStatusRequestBody = z.infer<
  typeof updateRelationTypeStatusRequestBody
>;

export type CompanyClaimStatusWithRelations =
  Prisma.CompanyClaimStatusGetPayload<{
    include: {
      mapping: {
        include: {
          stage: true;
        };
      };
    };
  }>;

export interface FetchCompanyClaimStatusResponseBody {
  data: CompanyClaimStatusWithRelations[];
  order_rules: CompanyClaimStatusOrderRule[];
}

export interface FetchCompanyComplaintStatusResponseBody {
  data: Array<
    Prisma.CompanyComplaintStatusGetPayload<{
      include: {
        public_status: true;
      };
    }>
  >;
}

export const delegateClaimHandlingRequestBody = z.discriminatedUnion(
  'strategy',
  [
    z.object({
      strategy: z.literal('users' satisfies DelegateClaimStrategy),
      users: z
        .array(
          z.object({
            id: z.number().min(1),
          }),
        )
        .min(1, 'Mínimo 1 usuario')
        .max(50, 'Máximo 50 usuarios')
        .refine(
          (users) => new Set(users.map((u) => u.id)).size === users.length,
          {
            message: 'Usuarios duplicados!',
          },
        ),
    }),
    z.object({
      strategy: z.literal('target_group' satisfies DelegateClaimStrategy),
      target_group: z.coerce.number().min(1),
    }),
  ],
  {
    required_error: requiredFieldMessage,
    invalid_type_error: requiredFieldMessage,
  },
);

export type DelegateClaimHandlingRequestBody = z.infer<
  typeof delegateClaimHandlingRequestBody
>;

export interface DelegateClaimHandlingResponseBody {
  delegatedCount: number;
}

export const listDelegatedClaimsRequestQuery = paginateRequestQuery.extend({});

export type ListDelegatedClaimsRequestQuery = z.infer<
  typeof listDelegatedClaimsRequestQuery
>;

export interface ListDelegatedClaimsResponseBody
  extends Pagination<
    Prisma.ClaimHandlerDelegatedGetPayload<{
      include: {
        claim: {
          select: {
            id: true;
            victim: {
              include: {
                legal_person: true;
                natural_person: true;
              };
            };
          };
        };
        destination: {
          select: {
            user: {
              select: {
                id: true;
                first_name: true;
                last_name: true;
                profile_photo: true;
                email: true;
              };
            };
          };
        };
        source: {
          select: {
            id: true;
            user: {
              select: {
                id: true;
                first_name: true;
                last_name: true;
                profile_photo: true;
                email: true;
              };
            };
          };
        };
      };
    }>
  > {}

export const restoreDelegatedClaimsRequestBody = z.object({
  ids: z
    .array(z.coerce.number().min(1))
    .min(1)
    .max(20)
    .refine((items) => new Set(items).size === items.length, {
      message: 'Duplicated ids',
    }),
});

export type RestoreDelegatedClaimsRequestBody = z.infer<
  typeof restoreDelegatedClaimsRequestBody
>;

export interface RestoreDelegatedClaimsResponseBody {
  restoredCount: number;
}

export const claimsFilterSchema = listInsurerClaimsRequestQuery.omit({
  search: true,
  page: true,
  limit: true,
});

export type ClaimsFilter = z.infer<typeof claimsFilterSchema>;

export const exportClaimsRequestBody = z.object({
  filters: claimsFilterSchema,
  columns: z
    .array(z.enum(claimReportColumns))
    .min(1, 'Mínimo 1 columna')
    .refine((cols) => new Set(cols).size === cols.length, {
      message: 'Duplicated columns',
    }),
});

export type ExportClaimsRequestBody = z.infer<typeof exportClaimsRequestBody>;

export interface ExportClaimsResponseBody {
  exported_report_id: number;
}

export type FetchInsurerMinimalClaimDetailResponseBody =
  Prisma.ClaimGetPayload<{
    include: {
      claim_type: true;
      responsibility_transfers: true;
      coordinator: {
        select: {
          profile_photo: true;
          id: true;
          first_name: true;
          last_name: true;
          email: true;
        };
      };
      company: {
        select: {
          id: true;
          country: true;
        };
      };
      damages: {
        select: {
          id: true;
          type: true;
        };
      };
      field_adjuster_contact: {
        select: {
          id: true;
          name: true;
        };
      };
      field_adjuster: {
        select: {
          id: true;
          first_name: true;
          last_name: true;
        };
      };
      created_by: {
        select: {
          id: true;
          email: true;
          first_name: true;
          last_name: true;
          profile_photo: true;
        };
      };
      owner: {
        select: {
          type: true;
          cellphone: true;
          id: true;
          email: true;
          first_name: true;
          last_name: true;
          profile_photo: true;
        };
      };
      occurrence: true;
      handlers: {
        include: {
          user: {
            select: {
              id: true;
              first_name: true;
              last_name: true;
              email: true;
              profile_photo: true;
            };
          };
        };
      };
      claim_status: {
        include: {
          mapping: {
            include: {
              stage: true;
            };
          };
        };
      };
      reference: true;
      stage: true;
    };
  }> & {
    can_create_offer: boolean;
    formatted_external_claim_id?: string | null;
  };

export type HandlerWithRelations = Prisma.ClaimHandlerGetPayload<{
  include: {
    user: {
      select: {
        id: true;
        first_name: true;
        last_name: true;
        profile_photo: true;
        email: true;
      };
    };
  };
}>;

export const attachFilesRequestBody = z.object({
  files: z
    .array(
      z.object({
        token: z.string().min(1),
        tags: z
          .array(z.number().positive())
          .max(2, 'Máximo 2 tags')
          .refine((tags) => new Set(tags).size === tags.length, {
            message: 'Duplicated tags',
          }),
      }),
    )
    .min(1),
});

export type AttachFilesRequestBody = z.infer<typeof attachFilesRequestBody>;

export interface AttachFilesResponseBody {
  attached: boolean;
}

export const listClaimFilesMinimalRequestQuery = listFilesRequestQuery.pick({
  types: true,
  search: true,
});

export interface ListClaimFilesMinimalRequestParams
  extends FetchResourceDetailRequestParams {}

export type ListClaimFilesMinimalRequestQuery = z.infer<
  typeof listClaimFilesMinimalRequestQuery
>;

export interface ListClaimFilesMinimalResponseBody {
  files: Array<
    FileWithURL & {
      preview_url: string;
      tags: Tag[];
      uploaded_by?: {
        email: string;
      } | null;
    }
  >;
}

export const listFieldAdjusterClaimsRequestQuery = paginateRequestQuery.extend({
  company: z.array(z.coerce.number().positive()).default([]),
  status: z.array(z.enum(fieldAdjusterClaimStatus)).default([]),
});

export type ListFieldAdjusterClaimsRequestQuery = z.infer<
  typeof listFieldAdjusterClaimsRequestQuery
>;

export interface ListFieldAdjusterClaimsResponseBody
  extends Pagination<
    Prisma.ClaimGetPayload<{
      include: {
        stage: true;
        reference: true;
        claimants: {
          select: {
            victim_relationship: true;
          };
        };
        ramo: true;
        claim_status: true;
        occurrence: true;
        victim: {
          include: {
            legal_person: true;
            natural_person: true;
          };
        };
        damages: true;
      };
    }> & {
      sinister: {
        id: number;
        external_complaints: ExternalComplaint[];
        external_complaint?: ExternalComplaint;
      };
    }
  > {}

export interface AssignFieldAdjusterRequestParams {
  claim_id: number;
  contact_id: number;
}

export interface AssignFieldAdjusterResponseBody {
  assigned: boolean;
}

export const transferClaimRequestBody = z.object({
  destination_user_email: z.string().email().min(1),
});

export type TransferClaimRequestBody = z.infer<typeof transferClaimRequestBody>;

export interface TransferClaimResponseBody {
  id: number;
}

export const assignClaimOwnerRequestBody = z.object({
  user_id: z.coerce.number().min(1, requiredFieldMessage),
});

export type AssignClaimOwnerRequestBody = z.infer<
  typeof assignClaimOwnerRequestBody
>;

export interface RetrieveClaimEstimatesResponseBody
  extends Prisma.ClaimGetPayload<{
    select: {
      id: true;
      material_judicial_amount: true;
      person_judicial_amount: true;
      material_administrative_amount: true;
      person_administrative_amount: true;
      administrative_amount: true;
      percentage_of_liability: true;
    };
  }> {}

export const createClaimOwnerRequestBody = z.object({
  user_id: z.coerce.number().min(1, requiredFieldMessage),
});

export const updateClaimEstimatesRequestBody = z.object({
  material_administrative_amount: z.coerce.number().optional(),
  person_administrative_amount: z.coerce.number().optional(),

  material_judicial_amount: z.coerce.number().optional(),
  person_judicial_amount: z.coerce.number().optional(),

  administrative_amount: z.coerce.number().optional(),
  percentage_of_liability: z.coerce.number().gte(0).lte(100).optional(),

  reason: z
    .string()
    .min(1, requiredFieldMessage)
    .max(255, 'Máximo 255 caracteres'),
});

export type UpdateClaimEstimatesRequestBody = z.infer<
  typeof updateClaimEstimatesRequestBody
>;

export type ClaimEstimatesProp = keyof Omit<
  UpdateClaimEstimatesRequestBody,
  'reason'
>;

export interface ListAvailableClaimStatusResponseBody {
  available_status: {
    stage: CompanyClaimStage;
    status: Prisma.CompanyClaimStatusGetPayload<{
      include: {
        mapping: {
          include: {
            stage: true;
          };
        };
      };
    }>[];
  }[];
}

export interface ListAvailableComplaintStatusResponseBody {
  available_status: {
    stage: CompanyComplaintStage;
    status: Prisma.CompanyComplaintStatusGetPayload<{
      include: {
        mapping: {
          include: {
            stage: true;
          };
        };
      };
    }>[];
  }[];
}

export const updateClaimRequestBody = createClaimSchema.partial().pick({
  victim: true,
  claimants: true,
  damages: true,
});

export type UpdateClaimRequestBody = z.infer<typeof updateClaimRequestBody>;

export interface UpdateClaimResponseBody {}

export const listVehicleMakeRequestQuery = z.object({
  search: z.string().nullish(),
  type: z.nativeEnum(VehicleTypes),
});

export type ListVehicleMakeRequestQuery = z.infer<
  typeof listVehicleMakeRequestQuery
>;

export interface ListVehicleMakeResponseBody {
  options: {
    label: string;
    value: string;
  }[];
}

export const listVehicleModelsRequestQuery = z.object({
  make: z.string().min(1),
  search: z.string().nullish(),
  type: z.nativeEnum(VehicleTypes),
});

export type ListVehicleModelsRequestQuery = z.infer<
  typeof listVehicleModelsRequestQuery
>;

export const listVehicleVersionsRequestQuery = z.object({
  make: z.string().min(1),
  model: z.string().min(1),
  year: z.coerce.number(),
  search: z.string().nullish(),
  type: z.nativeEnum(VehicleTypes),
});

export type ListVehicleVersionsRequestQuery = z.infer<
  typeof listVehicleVersionsRequestQuery
>;

export interface ListVehicleModelsResponseBody
  extends ListVehicleMakeResponseBody {}

export interface ListVehicleVersionsResponseBody
  extends ListVehicleModelsResponseBody {}

export const deriveClaimRequestBody = z.discriminatedUnion(
  'strategy',
  [
    z.object({
      reason: z.string().min(1, requiredFieldMessage),
      strategy: z.literal('user' satisfies DeriveClaimStrategy),
      user_id: z.number().min(1),
    }),
    z.object({
      reason: z.string().min(1, requiredFieldMessage),
      strategy: z.literal('target_group' satisfies DeriveClaimStrategy),
      target_group_id: z.coerce.number().min(1),
    }),
  ],
  {
    required_error: requiredFieldMessage,
    invalid_type_error: requiredFieldMessage,
  },
);

export type DeriveClaimRequestBody = z.infer<typeof deriveClaimRequestBody>;

export interface DeriveClaimResponseBody {
  id: number;
}

export const returnClaimResponsibilityRequestBody = z.object({
  reason: z.string().min(1, requiredFieldMessage),
});

export type ReturnClaimResponsibilityRequestBody = z.infer<
  typeof returnClaimResponsibilityRequestBody
>;

export const retrieveResponsibilityTransferRequestParams = z.object({
  responsibilityTransferId: z.coerce.number().positive().min(1),
});

export type RetrieveResponsibilityTransferRequestParams = z.infer<
  typeof retrieveResponsibilityTransferRequestParams
>;

export interface ListClaimJobsResponseBody {
  jobs: Prisma.JobGetPayload<{
    select: {
      started_at: true;
      id: true;
      type: true;
      failed_reason: true;
      failed_at: true;
      scheduled_at: true;
      completed_at: true;
      status: true;
    };
  }>[];
}
export interface ListComplaintJobsResponseBody
  extends ListClaimJobsResponseBody {}

export interface RetrieveClaimReferenceTimelineResponseBody {
  timeline: Prisma.ClaimStatusTransitionGetPayload<{
    select: {
      id: true;
      to_reference: {
        select: {
          id: true;
          name: true;
        };
      };
      created_at: true;
    };
  }>[];
}
