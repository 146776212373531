import { CountryCuit } from 'piramid-constants';
import { calculateVerificationNumber } from 'utils';

export const DEFAULT_CUIT_LENGTH = 11;

export const cuitCountries: {
  code: CountryCuit;
  name: string;
  type: string[];
  len: {
    from: number;
    to: number;
  };
  validator?: (value: string) => boolean;
}[] = [
  {
    code: 'ARG',
    name: 'Argentina',
    type: ['20', '23', '24', '25', '26', '27'],
    len: {
      from: 11,
      to: 11,
    },
    validator: (value: string) => {
      if (value.length !== DEFAULT_CUIT_LENGTH) {
        return false;
      }

      const verificationNumber = parseInt(
        value.charAt(DEFAULT_CUIT_LENGTH - 1),
        10,
      );

      const cuit = value.slice(0, -1);

      return verificationNumber === calculateVerificationNumber(cuit);
    },
  },
  {
    code: 'PRY',
    name: 'Paraguay',
    type: ['80'],
    len: {
      from: 8,
      to: 9,
    },
    validator: (value: string) => {
      if (value.slice(0, 2) !== '80') {
        return false;
      }

      return true;
    },
  },
];

export const cuitRefine = ({
  val,
  country,
}: {
  val: string;
  country: CountryCuit;
}) => {
  const targetCountry = cuitCountries.find((c) => c.code === country);

  console.log('targetCountry', targetCountry);

  if (!targetCountry) {
    return true;
  }

  if (
    val.length < targetCountry.len.from ||
    val.length > targetCountry.len.to
  ) {
    return false;
  }

  if (targetCountry.validator) {
    return targetCountry.validator(val);
  }

  return true;
};
