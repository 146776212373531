import QueryStringAddon from 'wretch/addons/queryString';

import wretch from 'wretch';

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

const publicRoutes = [
  '/claims/create',
  '/register',
  '/login',
  '/reset-password',
  '/forgot-password',
];

export const httpClient = wretch(BASE_URL)
  .middlewares([
    (next) => (url, opts) => {
      try {
        return next(url, opts).then((res) => {
          if (res.status === 401 && typeof window !== 'undefined') {
            const isPublic = publicRoutes.some((route) =>
              window.location.pathname.includes(route),
            );

            if (!isPublic) {
              window.location.href = '/login';
            }
          }

          return res;
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
  ])
  .options({
    credentials: 'include',
    mode: 'cors',
  })
  .addon(QueryStringAddon);
