import { VehicleCategoryTypes, VehicleUseTypes } from 'database';
import {
  driverVehicleOwnership,
  requiredFieldMessage,
  vehicleDocumentTypes,
  withLicensePlateVehicleTypes,
  withoutLicensePlateVehicleTypes,
} from 'piramid-constants';
import { z } from 'zod';
import { driverSchema } from './driver.contract';

export const licensePlateBaseSchema = z
  .string()
  .min(6, { message: 'Mínimo 6 caracteres' })
  .max(7, { message: 'Máximo 7 caracteres' })
  .regex(/^[a-z0-9]+$/i, { message: 'Solo se permiten letras y números' })
  .transform((val) => val?.toUpperCase());

const vehicleBaseSchema = z.object({
  market_value: z.string().optional(),
  driver: driverSchema.optional(),
  use: z.nativeEnum(VehicleUseTypes),
  make: z.string().min(1, requiredFieldMessage),
  model: z.string().min(1, requiredFieldMessage),
  vehicleId: z.coerce.number(),
  year: z.coerce.number().refine(
    (date) => {
      if (!date) {
        return false;
      }

      if (date > new Date().getFullYear()) {
        return false;
      }

      return true;
    },
    {
      message: requiredFieldMessage,
    },
  ),
});

export type VehicleBaseSchema = z.infer<typeof vehicleBaseSchema>;

const vehicleWithLicensePlateSchema = vehicleBaseSchema.extend({
  category: z.literal('with_license_plate' satisfies VehicleCategoryTypes),
  type: z.enum(withLicensePlateVehicleTypes),
  version: z.string().min(1, requiredFieldMessage),
  license_plate: licensePlateBaseSchema,
  chassis: z.string().optional(),
  engine: z.string().optional(),
});

export type VehicleWithLicensePlateSchema = z.infer<
  typeof vehicleWithLicensePlateSchema
>;
const vehicleWithoutLicensePlateSchema = vehicleBaseSchema.extend({
  category: z.literal('without_license_plate' satisfies VehicleCategoryTypes),
  type: z.enum(withoutLicensePlateVehicleTypes),
});

export type VehicleWithoutLicensePlateSchema = z.infer<
  typeof vehicleWithoutLicensePlateSchema
>;

export const vehicleSchema = z.discriminatedUnion('category', [
  vehicleWithLicensePlateSchema,
  vehicleWithoutLicensePlateSchema,
]);

export type VehicleSchema = z.infer<typeof vehicleSchema>;
