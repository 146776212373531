import { File, FileTypes, Prisma } from 'database';
import { z } from 'zod';
import { InferredEntityUnion } from './sinister.contract';
import { extendApi } from '@anatine/zod-openapi';

export const listFilesRequestQuery = z.object({
  types: z.array(z.nativeEnum(FileTypes)).default([]),
  tags: z.array(z.coerce.number().min(1)).default([]),
  search: z.string().optional(),
  limit: z.coerce.number().min(1).max(20).default(10).optional(),
  scope_type: z.enum([
    'claim',
    'sinister',
    'complaint',
    'external_complaint',
  ] as const),
  scope_id: z.coerce.number().min(1),
});

export type ListFilesRequestQuery = z.infer<typeof listFilesRequestQuery>;

export const tokenizeFileRequestBody = z.object({
  name: extendApi(z.string().min(1), {
    example: 'example.pdf',
  }),
  size: extendApi(z.coerce.number(), {
    example: 1000,
  }),
});

export type TokenizeFileRequestBody = z.infer<typeof tokenizeFileRequestBody>;

export interface UploadFileResponseBody {
  token: string;
  presigned_url: string;
}

export interface FileWithURL extends File {
  url: string;
  previewURL?: string;
}

export type FileWithTags = Prisma.FileGetPayload<{
  include: {
    tags: true;
  };
}>;

export interface FileWithURLAndTags extends FileWithURL, FileWithTags {
  token: string;
}

export interface TokenizeFileResponseBody {
  original_name: string;
  fields: object;
  presigned_url: string;
  token: string;
  stored_name: string;
  fetch_url: string;
}

export interface ListFilesResponseBody {
  files: Array<
    Prisma.FileGetPayload<{
      include: {
        validations: {
          select: {
            valid: true;
            validation: true;
            id: true;
            created_at: true;
          };
        };
        _count: {
          select: {
            validations: true;
            entities: true;
          };
        };
        uploaded_by: {
          select: {
            id: true;
            first_name: true;
            last_name: true;
            email: true;
          };
        };
        tags: true;
      };
    }> & { url: string; previewURL: string; entities: InferredEntityUnion[] }
  >;
}
