import { VehicleCategoryTypes, VehicleUseTypes } from 'database';
import {
  driverVehicleOwnership,
  requiredFieldMessage,
  vehicleDocumentTypes,
  withLicensePlateVehicleTypes,
  withoutLicensePlateVehicleTypes,
} from 'piramid-constants';
import { z } from 'zod';

export const licensePlateBaseSchema = z
  .string()
  .min(6, { message: 'Mínimo 6 caracteres' })
  .max(9, { message: 'Máximo 9 caracteres' })
  .regex(/^[a-z0-9]+$/i, { message: 'Solo se permiten letras y números' })
  .transform((val) => val?.toUpperCase());

const vehicleBaseSchema = z.object({
  market_value: z.string().optional(),
  document_type: z.enum(vehicleDocumentTypes),
  driver: z.object({
    ownership: z.enum(driverVehicleOwnership),
    first_name: z.string().min(1, requiredFieldMessage),
    last_name: z.string().min(1, requiredFieldMessage),
    cellphone: z.string().min(1, requiredFieldMessage),
  }),
  use: z.nativeEnum(VehicleUseTypes),
  make: z.string().min(1, requiredFieldMessage),
  model: z.string().min(1, requiredFieldMessage),
  vehicleId: z.coerce.number(),
  year: z.coerce
    .number()
    .max(new Date().getFullYear())
    .refine((date) => !!date, {
      message: requiredFieldMessage,
    }),
});

const vehicleWithLicensePlateSchema = vehicleBaseSchema.extend({
  category: z.literal('with_license_plate' satisfies VehicleCategoryTypes),
  type: z.enum(withLicensePlateVehicleTypes),
  version: z.string().optional(),
  license_plate: licensePlateBaseSchema,
  chassis: z.string().min(1, requiredFieldMessage),
  engine: z.string().min(1, requiredFieldMessage),
});

export type VehicleWithLicensePlateSchema = z.infer<
  typeof vehicleWithLicensePlateSchema
>;
const vehicleWithoutLicensePlateSchema = vehicleBaseSchema.extend({
  category: z.literal('without_license_plate' satisfies VehicleCategoryTypes),
  type: z.enum(withoutLicensePlateVehicleTypes),
});

export type VehicleWithoutLicensePlateSchema = z.infer<
  typeof vehicleWithoutLicensePlateSchema
>;

export const vehicleSchema = z.discriminatedUnion('category', [
  vehicleWithLicensePlateSchema,
  vehicleWithoutLicensePlateSchema,
]);

export type VehicleSchema = z.infer<typeof vehicleSchema>;
