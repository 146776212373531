import { countryAndStates } from 'piramid-constants';

export const countryRefine = (val: string): boolean =>
  countryAndStates.some((c) => c.code === val);

export const stateRefine = <T extends Partial<{ state: string }>>(
  arg: T,
  mapCountry: (data: T) => string,
): boolean => {
  if (!arg.state) return false;

  const country = countryAndStates.find((c) => c.code === mapCountry(arg));

  if (!country) return false;

  return country.states.some((s) => s.code === arg.state);
};
