import { calculateVerificationNumber } from 'utils';
import { z } from 'zod';
import { DEFAULT_CUIT_LENGTH, cuitRefine } from '../validations/cuit';
import { CountryCuit } from 'piramid-constants';

export const DEFAULT_DNI_LENGTH = 7;

export const einSchema = (country?: CountryCuit) => {
  return z
    .string()
    .max(
      DEFAULT_CUIT_LENGTH,
      `El CUIT no debe superar los ${DEFAULT_CUIT_LENGTH} dígitos`,
    )
    .refine(
      (value) => {
        if (!country) {
          return true;
        }

        return cuitRefine({ val: value, country });
      },
      {
        message: 'El CUIT ingresado no es válido',
      },
    );
};

export const cuitSchema = (country?: CountryCuit) => {
  return z
    .string()
    .max(
      DEFAULT_CUIT_LENGTH,
      `El CUIT no debe superar los ${DEFAULT_CUIT_LENGTH} dígitos`,
    )
    .refine(
      (value) => {
        if (!country) {
          return true;
        }

        return cuitRefine({ val: value, country });
      },
      {
        message: 'El CUIT ingresado no es válido',
      },
    );
};
// export const cuit = z
//   .string()
//   .max(DEFAULT_CUIT_LENGTH, 'El CUIT no debe superar los 13 dígitos')
//   .refine(
//     (value) => {
//       const einType = value.slice(0, 2)

//       const country = einCountries.find((c) => c.einType.includes(einType))

//       if (!country) {
//         return true
//       }

//       if (
//         value.length < country.einLength.from ||
//         value.length > country.einLength.to
//       ) {
//         return false
//       }

//       if (country.einValidator) {
//         return country.einValidator(value)
//       }

//       return true
//     },
//     {
//       message: 'El CUIT ingresado no es válido',
//     },
//   )

// export const cuitOptional = cuit.optional()

export const dni = z
  .string()
  .min(DEFAULT_DNI_LENGTH, `Mínimo ${DEFAULT_DNI_LENGTH} dígitos`)
  .max(8, `Máximo 8 dígitos`)
  .regex(/^\d+$/, 'Únicamente dígitos');

export const dniSchema = dni;
