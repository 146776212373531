import {
  ClaimantInstitutionRelationship,
  CoverageTypes,
  DamageTypes,
  MaterialDamageTypes,
  PersonDamageDetailSeverityTypes,
  PersonDamageDetailTypes,
  PersonDamageTypes,
  PolicyHolderRelationshipTypes,
  VictimRoleTypes,
} from 'database';
import {
  realPropertyTypes,
  requiredFieldMessage,
  realPropertyOwnershipTypes,
  bodyParts,
} from 'piramid-constants';
import { z } from 'zod';
import { vehicleSchema } from './vehicle.contract';
import Decimal from 'decimal.js';
import { amountSchema } from './amount.contract';
import { legalPersonSchema } from './claim.contracts';
import { countryRefine } from '../validations/country';
import { isValidPhoneNumber } from 'libphonenumber-js';

const insuranceSchema = z
  .object({
    coverage: z.nativeEnum(CoverageTypes),
    franchise_amount: z.coerce.number().nullish().nullable().optional(),
    insurerId: z.coerce.number().min(1, requiredFieldMessage),
    insurer: z.string().min(1, requiredFieldMessage),
    policy_number: z.string().min(1, requiredFieldMessage),
  })
  .refine(
    ({ coverage, franchise_amount }) => {
      if (coverage === 'all_risks_with_franchise' && !franchise_amount)
        return false;

      if (franchise_amount) {
        try {
          return new Decimal(franchise_amount);
        } catch (err) {
          return false;
        }
      }

      return true;
    },
    {
      message: requiredFieldMessage,
      path: ['franchise_amount'],
    },
  );

const realPropertySchema = z
  .object({
    isRealPropertyAddressSameAsVictimAddress: z.boolean(),
    country: z.string().min(1, requiredFieldMessage),

    address_street: z.string().min(1, requiredFieldMessage),
    address_number: z.string().min(1, requiredFieldMessage),
    address_apartment: z.string().nullish(),

    state: z.string().min(1, requiredFieldMessage),
    city: z.string().min(1, requiredFieldMessage),
    zip_code: z
      .string()
      .min(1, requiredFieldMessage)
      .regex(/^\d{4}$/, {
        message: 'Debe tener 4 dígitos',
      }),
    type: z.enum(realPropertyTypes),
    ownership: z.enum(realPropertyOwnershipTypes),
    other_ownership: z.string().nullish(),
    other_type: z.string().nullish(),
  })
  .refine(
    (values) => {
      if (values.type === 'other' && !values.other_type) return false;

      return true;
    },
    {
      path: ['other_type'],
      message: requiredFieldMessage,
    },
  )
  .refine(
    (values) => {
      if (values.ownership === 'other' && !values.other_ownership) return false;

      return true;
    },
    {
      path: ['other_ownership'],
      message: requiredFieldMessage,
    },
  );

export type RealPropertySchema = z.infer<typeof realPropertySchema>;

const materialDamageBaseSchema = z.discriminatedUnion('hasInsurance', [
  z.object({
    type: z.nativeEnum(MaterialDamageTypes),
    hasInsurance: z.literal(false),
  }),
  z.object({
    hasInsurance: z.literal(true),
    insurance: insuranceSchema,
  }),
]);

const materialDamageVehicle = z.intersection(
  materialDamageBaseSchema,
  z.object({
    type: z.literal('vehicle' satisfies MaterialDamageTypes),
    vehicle: vehicleSchema,
  }),
);

export type MaterialDamageVehicleSchema = z.infer<typeof materialDamageVehicle>;

const materialDamageRealProperty = z.intersection(
  materialDamageBaseSchema,
  z.object({
    type: z.literal('real_property' satisfies MaterialDamageTypes),
    real_property: realPropertySchema,
  }),
);

export type MaterialDamageRealPropertySchema = z.infer<
  typeof materialDamageRealProperty
>;

const materialDamageSubTypesSchema = z
  .union([materialDamageVehicle, materialDamageRealProperty])
  .describe('Datos del daño material');

export type MaterialDamageSubTypesSchema = z.infer<
  typeof materialDamageSubTypesSchema
>;

const damageBaseSchema = z.object({
  description: z.string().optional().nullable().nullish(),
  fixed: z.boolean().default(false),
});

const materialDamageSchema = damageBaseSchema.extend({
  type: z.literal('material' satisfies DamageTypes),
  material: materialDamageSubTypesSchema,
});

export type MaterialDamageSchema = z.infer<typeof materialDamageSchema>;

export const damageSchema = z.discriminatedUnion('type', [
  damageBaseSchema.merge(materialDamageSchema),
  damageBaseSchema.merge(
    z.object({
      type: z.literal('person' satisfies DamageTypes),
      person: z
        .object({
          driver: z
            .object({
              first_name: z.string().min(1, requiredFieldMessage),
              last_name: z.string().min(1, requiredFieldMessage),
              cellphone: z
                .string()
                .min(1, requiredFieldMessage)
                .refine(isValidPhoneNumber, {
                  message: 'Celular inválido',
                }),

              country: z
                .string()
                .min(1, requiredFieldMessage)
                .refine(countryRefine),
              state: z.string().min(1, requiredFieldMessage),
            })
            .optional()
            .nullish(),
          details: z.array(
            z.object({
              body_parts: z.array(z.enum(bodyParts)).default([]).optional(),
              severity: z.nativeEnum(PersonDamageDetailSeverityTypes),
              damage: z.nativeEnum(PersonDamageDetailTypes),
            }),
          ),
          hadSurgery: z.boolean(),
          hasHealthInsuranceFund: z.boolean(),
          health_insurance_fund: z.string(),
          surgery_description: z.string().optional().nullable().nullish(),
          wasTreatedByMedicalInstitution: z.boolean(),
          wasTreatedByWorkerCompensationInsurer: z.boolean(),
          hasIncomeVerification: z.boolean(),
          type: z.nativeEnum(PersonDamageTypes),
          treatment_worker_compensation_insurer_id: z.coerce.number(),
          treatment_worker_compensation_insurer: z
            .string()
            .optional()
            .nullable()
            .nullish(),
          treatment_medical_institution: z
            .string()
            .optional()
            .nullable()
            .nullish(),
          victim_role: z.nativeEnum(VictimRoleTypes),
          policyholder_relationship: z
            .nativeEnum(PolicyHolderRelationshipTypes)
            .or(z.literal(''))
            .transform((value) => value ?? undefined)
            .nullish(),
          policyholder_relationship_other: z.string().nullish(),
        })
        .refine(
          (damage) => {
            if (damage.victim_role !== 'policyholder_rider') return true;

            return !!damage.policyholder_relationship;
          },
          {
            path: ['policyholder_relationship'],
            message: requiredFieldMessage,
          },
        )
        .refine(
          (personDamage) => {
            if (
              personDamage.policyholder_relationship === 'other' &&
              !personDamage.policyholder_relationship_other
            )
              return false;

            return true;
          },
          {
            message: 'Debe indicar el vinculo',
            path: ['policyholder_relationship_other'],
          },
        ),
    }),
  ),
  damageBaseSchema.merge(
    z.object({
      type: z.literal('recovery' satisfies DamageTypes),
      recovery: z.object({
        claimant_institution_relationship: z.nativeEnum(
          ClaimantInstitutionRelationship,
        ),
        institution: legalPersonSchema,
      }),
    }),
  ),
]);
